<template>
    <LargeColumnWithSidebar>
        <LunchMenuProvider v-slot="{ lunchMenu, loaded }">
            <div class="whiteBox whiteBox--padding">
                <div
                    v-if="lunchMenu"
                    class="fadeIn"
                >
                    <div class="top">
                        <h2>
                            Lunchmeny
                        </h2>

                        <Content>
                            <p>Här hittar du veckans lunchmeny.</p>
                        </Content>
                    </div>

                    <LunchMenu :menu="lunchMenu" />

                    <LunchInfo />
                </div>

                <Spinner
                    v-else-if="!loaded"
                    class="fadeIn"
                />

                <Content
                    v-else-if="loaded"
                    class="fadeIn"
                >
                    <p>
                        Det finns ingen lunchmeny för denna veckan.
                    </p>
                </Content>
            </div>
        </LunchMenuProvider>

        <template v-slot:sidebar>
            <Reminders />
        </template>
    </LargeColumnWithSidebar>
</template>

<script>
import LargeColumnWithSidebar from '@/components/layout/LargeColumnWithSidebar';
import LunchMenuProvider from '@/components/lunchMenu/LunchMenuProvider';
import LunchMenu from '@/components/lunchMenu/LunchMenu';
import LunchInfo from '@/components/lunchMenu/LunchInfo';
import Content from '@/components/ui/Content';
import Spinner from '@/components/ui/Spinner';
import Reminders from '@/components/reminders/Reminders';

export default {
    components: {
        LargeColumnWithSidebar,
        LunchMenuProvider,
        LunchMenu,
        LunchInfo,
        Content,
        Spinner,
        Reminders
    }
};
</script>

<style lang="postcss" scoped>
.top {
    margin-bottom: 3rem;
    @media (--laptop) {
        margin-bottom: 2.5rem;
    }
    @media (--mobile) {
        margin-bottom: 2rem;
    }
}

.spinner {
    color: var(--primaryColor);
    width: 3rem;
    height: 3rem;
    margin: 0 auto;
}
</style>
